/*
  Component specific stylesheet
*/

/* Fonts */
@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'), url(https://fonts.gstatic.com/s/roboto/v15/2tsd397wLxj96qwHyNIkxHYhjbSpvc47ee6xR_80Hnw.woff2) format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfbO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v15/CrYjSnGjrRCn0pd9VQsnFOvvDin1pK8aKteLpeZ5c0A.woff) format('woff');
}

/* Override */
p {
  font-weight: 300;
}

/* Headings */
h2.heading {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  padding: 50px 0;
  letter-spacing: 4px;
}
h3.heading {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

/* Paragraph */
p.text {
  font-size: 16px;
  line-height: 1.8em;
}
p.fine-text {
  font-size: 12px;
  line-height: 1.4em;
}

/* List */
ol.list,
ul.list {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.8em;
  margin: 14px;
  margin-left: 18px;
}

/* Buttons */
a.button {
  background: #fbaf5d;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  line-height: 1.4em;
  font-weight: 500;
  padding: 10px 16px;
  border-radius: 2px;
  min-width: 140px;
}
a.button:hover {
  background: #EF6C00;
}
a.button.teal {
  background: #009688;
}
a.button.teal:hover {
  background: #00796B;
}
a.button.indigo {
  background: #3f51b5;
}
a.button.indigo:hover {
  background: #3949AB;
}
a.button.large {
  min-width: 280px;
}

/* Links */
a.link {
  color: #ff9800;
}
a.link.white {
  color: #ff9800;
}

hr {
  border-top: 1px solid #e2e2e2;
}

/* Icons */

/* Colors */
.color-teal {
  color: #009688;
}
.color-indigo {
  color: #3f51b5;
}
.color-facebook {
  color: #3b5998;
}
.color-twitter {
  color: #55acee;
}
.color-linkedin {
  color: #0077b5;
}
.color-ppc {
  color: #00a4d3;
}
.color-ppp {
  color: #ffc957;
}
.color-autodonate {
  color: #ef6468;
}
