/* MAIN */
@media only screen and (max-width: 800px) {
  /* MAIN */
  .main {
    font-family: 'Roboto', sans-serif;
    color: #7A7A7A;
    font-size: 14px;
    margin: 0 auto;
  }

  /* HEAD */
  .head,
  .head.dark {
    padding: 14px;
    position: relative;
    width: auto;
  }

  /* HEAD toolbar */
  .head .toolbar {
    text-align: left;
  }
  .head .toolbar a {
    text-decoration: none;
  }
  .head .toolbar img.logo-icon {
    width: 24px;
    margin-right: 4px;
    float: left;
  }
  .head .toolbar span.brand {
    color: #F57C00;
    font-size: 16px;
    letter-spacing: 3px;
    line-height: 24px;
    text-shadow: none;
  }
  .head .toolbar i.menu-button {
    float: right;
    font-size: 20px;
    color: #737373;
    cursor: pointer;
    display: block;
  }
  /* HEAD navigation */
  .head .navigation {
    display: none;
    float: none;
    margin-top: 0;
  }
  .head .navigation ul {
    font-size: 16px;
    padding-top: 14px;
    list-style: none;
  }
  .head .navigation ul li {
    display: block;
    border-bottom: 1px solid #EAEAEA;
  }
  .head .navigation ul li:first-child {
    border-top: 1px solid #EAEAEA;
  }
  .head .navigation ul li a,
  .head.dark .navigation ul li a {
    text-decoration: none;
    color: #737373;
    width: 100%;
    display: block;
    cursor: pointer;
    padding: 14px 0;
    margin-left: 0;
    text-shadow: none;
  }
  .head .navigation ul li a:hover {
    border-bottom: none;
  }
  .head .navigation ul li a.active {
    border-bottom: 1px solid #F57C00;
  }

  /* BODY */
  .body {
    min-height: 550px;
  }

  /* BODY overview */
  .body .overview,
  .body .overview.splash-1,
  .body .overview.splash-2 {
    color: white;
    background: url('/build/images/splash-md.jpg') center no-repeat;
    background-color: #232323;
    background-size: cover;
    padding: 60px 0 30px;
  }
  .body .overview.splash-1 {
    background-image: url('/build/images/advertiser-md.jpg');
  }
  .body .overview.splash-2 {
    background-image: url('/build/images/influencer-md.jpg');
  }
  .body .overview .hero {
    font-size: 28px;
    line-height: 1.2em;
    text-align: center;
    margin-bottom: 50px;
  }
  .body .overview .hero small {
    font-size: 20px;
    font-weight: 200;
    display: block;
  }
  .body .overview .content {
    display: flex;
    margin-bottom: 50px;
  }
  .body .overview .content div {
    text-align: center;
    margin: 14px;
    width: 100%;
  }
  .body .overview .content i {
    font-size: 3em;
  }
  .body .overview .social {
    float: right;
  }

  .body .user-overview {
    margin: 14px;
  }
  .body .user-overview .platforms {
    display: flex;
  }
  .body .user-overview .platforms div {
    margin: 14px;
    text-align: center;
    width: 100%;
    line-height: 1.6em;
  }
  .body .user-overview .actions {
    text-align: center;
  }
  .body .user-overview i {
    font-size: 3em;
  }

  /* BODY autodonate-overview */
  .body .autodonate-overview {
    background: #ef6468;
    color: white;
    padding: 14px;
    text-align: center;
  }
  .body .autodonate-overview a {
    color: white;
  }

  /* BODY generic-content */
  .body .generic-content {
    margin: 0 14px;
  }
  .body .generic-content h3 {
    text-align: left;
    margin: 14px 0;
    line-height: 1.4em;
  }

  /* FOOT */
  .foot {
    border-top: 1px solid #EAEAEA;
    margin: 50px 10px 10px;
    padding-top: 50px;
    text-align: center;
  }
  .foot ul {
    list-style: none;
  }
  .foot ul li {
    padding: 0 6px;
    display: inline-block;
  }
  .foot ul li a {
    text-decoration: none;
  }
  .foot img.logo-icon {
    width: 24px;
    filter: grayscale(100%) brightness(1);
  }

  /* COMPONENTS */
  h2.heading {
    font-size: 20px;
  }
  h3.heading {
    font-size: 16px;
  }
}
