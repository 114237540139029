/* MAIN */
.main {
  font-family: 'Roboto', sans-serif;
  color: #7A7A7A;
  font-size: 14px;
  margin: 0 auto;
}

/* HEAD */
.head {
  padding: 20px 28px;
}
.head.dark {
  position: absolute;
  width: 100%;
}

/* HEAD toolbar */
.head .toolbar {
  text-align: left;
}
.head .toolbar a {
  text-decoration: none;
}
.head .toolbar img.logo-icon {
  width: 24px;
  margin-right: 4px;
  float: left;
}
.head .toolbar span.brand {
  color: #F57C00;
  font-size: 16px;
  letter-spacing: 4px;
  line-height: 24px;
}
.head .toolbar i.menu-button {
  display: none;
}
/* HEAD navigation */
.head .navigation {
  float: right;
  margin-top: -20px;
}
.head .navigation ul {
  list-style: none;
  font-size: 16px;

  /*text-align: center;*/
}
.head .navigation ul li {
  /*padding: 14px 0;*/
  display: inline-block;
}
.head .navigation ul li a {
  text-decoration: none;
  color: #7A7A7A;
  display: inline-block;
  cursor: pointer;
  margin-left: 30px;
  font-weight: 300;
}
.head.dark .navigation ul li a {
  color: #EEEEEE;
  text-shadow: 0 0 20px #232323;
}
.head .navigation ul li a:hover,
.head .navigation ul li a.active {
  border-bottom: 1px solid #F57C00;
}

/* BODY */
.body {
  min-height: 550px;
}

/* BODY overview */
.body .overview,
.body .overview.splash-1,
.body .overview.splash-2 {
  color: white;
  background: url('/build/images/splash-md.jpg') center no-repeat;
  background-color: #232323;
  background-size: cover;
  padding: 160px 0 30px;
}
.body .overview.splash-1 {
  background-image: url('/build/images/advertiser-md.jpg');
}
.body .overview.splash-2 {
  background-image: url('/build/images/influencer-md.jpg');
}
.body .overview .hero {
  font-size: 38px;
  font-size: 4vw;
  font-weight: 400;
  line-height: 1.6em;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 100px;
  text-shadow: 0 0 20px #232323;
}
.body .overview .hero small {
  font-size: 24px;
  font-weight: 200;
  display: block;
}
.body .overview .content {
  display: flex;
  max-width: 800px;
  margin: 50px auto;
}
.body .overview .content div {
  text-align: center;
  margin: 14px;
  width: 100%;
}
.body .overview .content i {
  font-size: 5em;
}
.body .overview .social {
  float: right;
}
.body .user-overview i {
  font-size: 5em;
}

.body .user-overview {
  margin: 14px auto;
  max-width: 800px;
}
.body .user-overview .platforms {
  display: flex;
}
.body .user-overview .platforms div {
  margin: 14px;
  text-align: center;
  width: 100%;
  line-height: 1.6em;
}
.body .user-overview .actions {
  text-align: center;
}
.body .boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: #fafafa;
  border: 1px solid #e2e2e2;
  border-left: none;
  border-right: none;
}
.body .box {
  width: 400px;
  padding: 60px 20px 20px;
  display: inline-block;
}
.body .box p {
  text-align: center;
}

/* BODY autodonate-overview */
.body .autodonate-overview {
  background: #ef6468;
  color: white;
  padding: 14px;
  text-align: center;
}
.body .autodonate-overview a {
  color: white;
}

/* BODY generic-content */
.body .generic-content {
  max-width: 800px;
  margin: 0 auto;
}
.body .generic-content h3 {
  text-align: left;
  margin: 14px 0;
  line-height: 1.4em;
}

/* FOOT */
.foot {
  border-top: 1px solid #EAEAEA;
  margin: 50px 10px 10px;
  padding-top: 50px;
  text-align: center;
}
.foot a.link {
  color: #7A7A7A;
}
.foot a.link:hover {
  text-decoration: underline;
}
.foot ul {
  list-style: none;
}
.foot ul li {
  padding: 0 6px;
  display: inline-block;
}
.foot ul li a {
  text-decoration: none;
  line-height: 1.6em;
}
.foot img.logo-icon {
  width: 24px;
  filter: grayscale(100%) brightness(1);
}
